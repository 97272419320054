<template>
  <footer class="main-footer">
    <strong>
      Copyright &copy; 2014-2019
      <a href="http://adminlte.io">AdminLTE.io</a>.
    </strong>
    All rights reserved.
    <div class="float-right d-none d-sm-inline-block">
      <b>Version</b> 3.0.0-beta.2
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scoped>
</style>