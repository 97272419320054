<template>
  <div class="login-box">
    <div class="login-logo">
      <router-link to="/">
        <b>Kernel</b> Dashboard
      </router-link>
    </div>
    <!-- /.login-logo -->
    <div class="card">
      <div class="card-body login-card-body">
        <p class="login-box-msg">Log In</p>
        <form @submit.prevent="logIn">
          <div class="input-group mb-3">
            <input
              type="text"
              ref="username"
              class="form-control"
              placeholder="Username"
              v-model="loginData.username"
            >
            <div class="input-group-append">
              <span class="fas fa-envelope input-group-text"></span>
            </div>
          </div>
          <div class="input-group mb-3">
            <input
              type="password"
              ref="password"
              class="form-control"
              placeholder="Password"
              v-model="loginData.password"
            >
            <div class="input-group-append">
              <span class="fas fa-lock input-group-text"></span>
            </div>
          </div>
          <input type="submit" style="visibility: hidden">
          <div class="row justify-content-end">
            <!-- /.col -->
            <div class="col-4">
              <button
                type="button"
                class="btn btn-primary btn-block btn-flat"
                @click="logIn"
              >Log In</button>
            </div>
            <!-- /.col -->
          </div>
        </form>
      </div>
      <!-- /.login-card-body -->
    </div>
  </div>
</template>


<script>
import auth from "./../controllers/auth.controller";
export default {
  name: "Login",
  data: function() {
    return {
      loginData: {
        username: "",
        password: ""
      }
    };
  },
  methods: {
    logIn: function() {
      console.log(this.loginData);
      auth.logIn(this.loginData);
    }
  },
  created: function() {
    document.body.classList.add("login-page");
  },
  destroyed: function() {
    document.body.classList.remove("login-page");
  }
};
</script>

<style scoped>
</style>
