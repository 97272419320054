import axios from './../utils/axios';
import store from './../store';
import router from './../router/index';
import swal from 'sweetalert';


/* eslint-disable */

export default {
  logIn(data) {
    axios
      .post('/auth/login', data)
      .then(async response => {
        await store.dispatch('setToken', "NN");
        // await store.dispatch('setToken', response.data.token);
        // axios.defaults.headers.common['Authorization'] =
        //   'Bearer ' + store.getters.getToken;
        console.log(response);
        await router.push("/");
      })
      .catch(e => {
        console.log('error', e);

        swal({
          title: 'Authorization Error',
          text: 'Credentials are incorrect',
          icon: 'error'
        }).then(response => {});
      });
  }
};
