<template>
  <aside style="position: fixed!important;"
				class="layout-fixed main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <router-link to="/" class="brand-link">
      <img
        :src="require('../../assets/img/logo.png')"
        alt="Kernel Logo"
        class="brand-image img-circle elevation-3"
        style="opacity: .8"
      />
      <span class="brand-text font-weight-light">Kernel Dashboard</span>
    </router-link>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <!-- Add icons to the links using the .nav-icon class
          with font-awesome or any other icon font library-->

          <li class="nav-item has-treeview">
            <router-link to="/" class="nav-link">
              <i class="mr-3 nav-icon fas fa-th"></i>
              <p>Dashboard</p>
            </router-link>
          </li>
          <li class="nav-item has-treeview">
            <router-link to="/subscriptions" class="nav-link">
              <i class="mr-3 nav-icon fas fa-money-bill-wave"></i>
              <p>Subscriptions</p>
            </router-link>
          </li>
          <li class="nav-item has-treeview">
            <router-link to="/users" class="nav-link">
              <i class="mr-3 nav-icon fas fa-user"></i>
              <p>Users</p>
            </router-link>
          </li>
          <li class="nav-item has-treeview">
            <router-link to="/invoices" class="nav-link">
              <i class="mr-3 nav-icon fas fa-folder"></i>
              <p>Invoices</p>
            </router-link>
          </li>
          <li class="nav-item has-treeview">
            <router-link to="/deployment" class="nav-link">
              <i class="mr-3 nav-icon fas fa-upload"></i>
              <p>Deployment</p>
            </router-link>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
export default {
  name: "SidebarMenu",
  data: function() {
    return {
      isMenuOpen: false
    };
  },
  computed: {
    authUser() {
      return this.$store.getters.getAuthUser;
    }
  },
  methods: {
    toogleMenu: function(event) {
      event.stopPropagation();
      event.preventDefault();
      this.isMenuOpen = !this.isMenuOpen;
    }
  }
};
</script>

<style>
</style>