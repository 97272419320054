<template>
  <div>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid content-box">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Deployment</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Deployment</li>
            </ol>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>
    <div class="row justify-content-center">
      <div class="col col-8">
        <div class="row justify-space-between">
          <div class="col col-3">
            <label class="m-0 mr-3 mt-2 mb-3 text-dark" style="display: inline-block;">Env</label>
            <select :disabled="inProgress" v-model="env" class="form-select mr-4" style="width: auto!important;" aria-label="Country Select">
              <option v-for="(e) in env_list" :value="e.name" v-bind:key="e.name">{{ e.label }}</option>
            </select>
          </div>
          <div class="col col-3">
            <label class="m-0 mr-3 mt-2 mb-3 text-dark" style="display: inline-block;">Option</label> <br/>
            <select :disabled="inProgress" v-model="rebuild" class="form-select mr-4" style="width: auto!important;" aria-label="Country Select">
              <option :value="true">Rebuild</option>
              <option :value="false" selected>Restart</option>
            </select>
          </div>
          <div class="col col-3">
            <label class="m-0 mr-3 mt-2 mb-1 text-dark" style="display: inline-block;">Action</label> <br/>
            <button :disabled="inProgress" class="btn btn-success" @click="start">Deploy</button>
          </div>
          <div class="col col-3">
            <label class="m-0 mr-3 mt-2 mb-1 text-dark" style="display: inline-block;">Action</label> <br/>
            <button :disabled="inProgress" class="btn btn-danger" @click="stop">Stop</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="inProgress" class="row justify-content-center">
      <div class="col col-8">
        <div class="row justify-content-center align-center text-center">
          Loading...
        </div>
      </div>
    </div>
    <div v-if="!!message" class="mt-8 row justify-content-center">
      <div class="col col-8">
        <div class="alert alert-success" role="alert">
          {{ message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/utils/axios";

export default {
  name: "Deployment",
  data() {
    return {
      env : 'dev',
      env_list : [
        {
          name : 'dev',
          label : 'Development'
        },
        {
          name : 'prod',
          label : 'Production'
        },
        {
          name : 'stable',
          label : 'Stable'
        }
      ],
      rebuild: false,
      message: '',
      inProgress: false,
    };
  },
  methods: {
    async start() {
      if (this.inProgress) {
        return;
      }
      this.inProgress = true;
      try {
        const response = await axios.post(`/admin/deployment/start?env=${this.env}&rebuild=${this.rebuild? "true" : ""}`);
        this.message = response.data.message;
      } catch (e) {
        this.message = e.response.data.message;
      }
      this.inProgress = false;
    },
    async stop() {
      if (this.inProgress) {
        return;
      }
      this.inProgress = true;
      try {
        const response = await axios.post(`/admin/deployment/stop?env=${this.env}`);
        this.message = response.data.message;
      } catch (e) {
        this.message = e.response.data.message;
      }
      this.inProgress = false;
    }
  },
}
</script>

<style scoped>

</style>