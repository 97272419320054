<template>
  <div>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <div class="container-fluid content-box">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Subscriptions</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Subscriptions</li>
            </ol>
          </div>
        </div>
      </div>
      <!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3">
            <!-- Profile Image -->
            <div class="card card-primary card-outline">
              <div class="card-body box-profile">
                <div class="text-center">
									<h4>Activate Subscription</h4>
                </div>
								<div class="form text-center px-5">
									<label class="mt-1 form-group full-width">
										User ID to activate<br>
										<input name="id" v-model="activation_form.id" type="number" class="full-width" required>
									</label><br>
									<label class="mt-1 form-group full-width">
										Expiration Date<br>
										<input name="expiration" v-model="activation_form.expiration" type="date" class="full-width text-center" style="height: 34px;"  required>
									</label><br>
									<label class="my-3 form-group">
										Plan to activate:
										<select name="plan_name" v-model="activation_form.plan_name" class="ml-3" style="width: 120px">
											<option value="premium">Premium</option>
                      <option value="premium_annual">Premium Annual</option>
                      <option value="premium_triennial">Premium Triennial</option>

                      <option value="sme_premium_annual">SME Annual</option>
                      <option value="sme_premium_triennial">SME Triennial</option>
                      <option value="sme_premium_lifetime">SME Lifetime</option>

                      <option value="weekly_premium_annual">Weekly Annual</option>
                      <option value="weekly_premium_triennial">Weekly Triennial</option>
                      <option value="weekly_premium_lifetime">Weekly Lifetime</option>

										</select>
									</label><br>
									<button @click="activateSub" class="btn btn-primary btn-block mt-2">
										<b>Activate</b>
									</button>
								</div>
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="card">
              <div class="card-header p-2">
								<div class="row mt-2">
									<div class="col-3">
										<label>
											Status
											<input class="ml-2" type="text" v-model="status">
										</label>
									</div>
									<div class="col-3">
										<label>
											Provider
											<input class="ml-2" type="text" v-model="provider">
										</label>
									</div>
								</div>
              </div>
              <div class="card-body">
								<div class="row mx-auto text-center" style="overflow-y: scroll; height: 65vh; text-align: center;">
									<table class="table">
										<thead>
											<tr>
												<th>User ID</th>
                        <th>Company ID</th>
												<th>User Email</th>
												<th>Plan</th>
												<th>Status</th>
												<th>Activation Date</th>
												<th>Expiration Date</th>
												<th>Provider</th>
												<th>Creation date</th>
											</tr>
										</thead>
										<tbody>
											<tr :key="sub.id" v-for="sub in subscriptions">
												<td>
													{{sub.user_id}}
												</td>
                        <td>
                          {{sub.company_id}}
                        </td>
												<td>
													{{sub.user_email}}
												</td>
												<td>
													{{sub.plan_name}}
												</td>
												<td>
													{{sub.status}}
												</td>
												<td>
													{{sub.activation}}
												</td>
												<td>
													{{sub.expiration}}
												</td>
												<td>
													{{sub.provider}}
												</td>
												<td>
													{{sub.created_at}}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "@/utils/axios";

export default {
  name: "Subscriptions",
  data() {
    return {
			status: "",
			provider: "",
			subscriptions: [],
      selectedTab: "INFO",
			activation_form : {
				id: 0,
				expiration: "",
				plan_name: "free",
			}
    };
  },
	beforeMount() {
		this.fetchSubs();
	},
	watch: {
		status() {
			this.fetchSubs();
		},
		provider() {
			this.fetchSubs();
		}
	},
	methods: {
		async fetchSubs() {
			this.subscriptions = (await axios.get(`/admin/account-subscriptions?status=${this.status}&provider=${this.provider}`)).data;
		},
		async activateSub() {
        this.activation_form.id = parseInt(this.activation_form.id)
			try {
				await axios.post("/admin/activate-subscription", this.activation_form);
				await this.fetchSubs()
			} catch (e) {
				console.error(e);
			}
		}
	}
};
</script>

<style>
.nav-link.active:hover {
  color: white !important;
}
.full-width {
	width: 100% !important;
}
</style>
