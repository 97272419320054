<template>
	<div>
		<!-- Content Header (Page header) -->
		<section class="content-header">
			<div class="container-fluid content-box">
				<div class="row mb-2">
					<div class="col-sm-6">
						<h1>Users</h1>
					</div>
					<div class="col-sm-6">
						<ol class="breadcrumb float-sm-right">
							<li class="breadcrumb-item">
								<router-link to="/">Home</router-link>
							</li>
							<li class="breadcrumb-item active">Users</li>
						</ol>
					</div>
				</div>
			</div>
			<!-- /.container-fluid -->
		</section>

		<!-- Main content -->
		<section class="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-md-3">
						<!-- Profile Image -->
						<div class="card card-primary card-outline">
							<div class="card-body box-profile">
								<div class="text-center">
									<H3>Export</H3>
								</div>
								<div class="form text-center px-5">
									<form action="/api/admin/users-excel" method="get" class="text-center">
										<label>
											From
											<input type="date" name="from">
										</label>
										<label>
											To
											<input type="date" name="to">
										</label><br>
										<input type="submit" value="Get users sheet" class="btn btn-secondary">
										<br>
										<input type="radio" name="format" value="users" checked="checked" />Users<br>
										<input type="radio" name="format" value="sgrid_hasInv" />SendGrid Has Invoice<br>
										<input type="radio" name="format" value="sgrid_VerifnoInv" />SendGrid Verified No Invoice<br>
										<input type="radio" name="format" value="sgrid_noVerif" />SendGrid Not verified <br>
									</form>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-9">
						<div class="card">
							<div class="card-header p-2">
                Companies with at least 2 invoices
							</div>
							<div class="card-body">
                <div class="row mx-auto text-center" style="overflow-y: scroll; height: 65vh; text-align: center;">
                  <table class="table">
                    <thead>
                    <tr>
                      <th>Company ID</th>
                      <th>Company Email</th>
                      <th>Count</th>
                      <th>Time Between</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr :key="e.account_id" v-for="e in first_to_second">
                      <td>
                        {{e.account_id}}
                      </td>
                      <td>
                        {{e.email}}
                      </td>
                      <td>
                        {{e.count}}
                      </td>
                      <td>
                        {{e.time_between}}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import axios from "@/utils/axios";

export default {
  name: "Users",
  data(){
    return {
      first_to_second:[]
    }
  },
  async beforeMount() {
    try {
      this.first_to_second = (await axios.get("/admin/invoices/first-to-second")).data;
    }catch (e) {
      console.error(e);
    }
  }
}
</script>

<style scoped>

</style>
