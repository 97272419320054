<script>
import {Doughnut, mixins} from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Doughnut,
	mixins: [reactiveProp],
  name: "DoughnutChart",
  props: ['chartData', 'options'],
  mounted () {
    this.renderChart(this.chartdatac, this.optionsc)
  },
  computed: {
    chartdatac() {
      return this.chartData;
    },
    optionsc() {
      return this.options;
    }
  }
}
</script>

<style scoped>

</style>