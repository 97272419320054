import Vue from 'vue';
import App from './App.vue';

import instance from "@/utils/axios";
import store from './store';
import router from './router';

Vue.config.productionTip = false;
Vue.prototype.$http = instance;

new Vue({
  store: store,
  router: router,
  render: h => h(App)
}).$mount('#app');
