import Vue from 'vue';
import Router from 'vue-router';
import store from './../store';

import Main from './../pages/Main';
import Login from './../pages/Login';

import Blank from './../pages/Blank';
import Dashboard from './../pages/Dashboard';
import Invoices from './../pages/Invoices';
import Subscriptions from "@/pages/Subscriptions";
import Users from "@/pages/Users";
import Deployment from "@/pages/Deployment";

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Main,
      beforeEnter(to, from, next) {
        if (store.state.token) {
          next();
        } else {
          next("/login");
        }
      },
      children: [
        { path: '/blank', component: Blank },
        { path: 'subscriptions', component: Subscriptions },
        { path: 'users', component: Users },
        { path: 'invoices', component: Invoices },
        { path: 'deployment', component: Deployment },
        { path: '/', component: Dashboard }
      ]
    },
    {
      path: '/login',
      component: Login,
      beforeEnter(to, from, next) {
        if (store.state.token) {
          next('/');
        } else {
          next();
        }
      }
    }
  ]
});
