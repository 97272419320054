<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid content-box">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0 mr-3 mt-2 mb-3 text-dark" style="display: inline-block;">Kernel Stats</h1>
            <select v-model="country" class="form-select mr-4" style="width: auto!important;" aria-label="Country Select">
              <option selected value="">Country (any)</option>
              <option value="Georgia">Georgia</option>
              <option value="Nigeria">Nigeria</option>
              <option value="Philippines">Philippines</option>
              <option value="Pakistan">Pakistan</option>
              <option value="India">India</option>
            </select>
						<label>
							<span style="font-weight: normal;" class="mr-2">From</span>
							<input style="height: 40px" type="date" v-model="dateFrom">
						</label>
          </div>
          <!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li class="breadcrumb-item active">Dashboard v1</li>
            </ol>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <!-- Small boxes (Stat box) -->
        <div class="row">
          <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-blue-light">
              <div class="inner">
                <h3 style="display: inline-block">{{dashboard_stats.invoices.all}}</h3>
								<span style="color: greenyellow" >({{formatIncreases(dashboard_stats.invoices)}})</span>*
                <span style="font-size: 10px;display: inline-block;position: relative;top: -3px;">last 24h / 7d / 30days</span>
                <p >New Invoices</p>
              </div>
              <div class="icon">
                <i class="ion ion-document-text"></i>
              </div>
              <a href="#" class="small-box-footer">
                More info
                <i class="fas fa-arrow-circle-right"></i>
              </a>
            </div>
          </div>
          <!-- ./col -->
          <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-green">
              <div class="inner">
                <h3 style="display: inline-block"> {{dashboard_stats.subscriptions.all}} </h3>
                <span style="color: green" >({{formatIncreases(dashboard_stats.subscriptions)}})</span>*
                <span style="font-size: 10px;display: inline-block;position: relative;top: -3px;">last 24h / 7d / 30days</span>

                <p>Active Subscriptions</p>
              </div>
              <div class="icon">
                <i class="ion ion-stats-bars"></i>
              </div>
              <router-link to="/subscriptions" class="small-box-footer">
                More info
                <i class="fas fa-arrow-circle-right"></i>
							</router-link>
            </div>
          </div>
          <!-- ./col -->
          <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-coral">
              <div class="inner">
                <h3 style="display: inline-block"> {{dashboard_stats.users.all}} </h3>
                <span style="color: green" >({{formatIncreases(dashboard_stats.users)}})</span>*
                <span style="font-size: 10px;display: inline-block;position: relative;top: -3px;">last 24h / 7d / 30days</span>

                <p>User Registrations</p>
              </div>
              <div class="icon">
                <i class="ion ion-person-add"></i>
              </div>
              <a href="#" class="small-box-footer">
                More info
                <i class="fas fa-arrow-circle-right"></i>
              </a>
            </div>
          </div>
          <!-- ./col -->
          <div class="col-lg-3 col-6">
            <!-- small box -->
            <div class="small-box bg-yellow">
              <div class="inner">
                <h3>
									{{dashboard_stats.users.all-dashboard_stats.users.not_verified}} |
									{{dashboard_stats.users.returning}} |
                  {{dashboard_stats.churn.truly_active}}
								</h3>

                <p>Verified | Returning Users | Truly Active</p>
              </div>
              <div class="icon">
                <i class="ion ion-pie-graph"></i>
              </div>
              <a href="#" class="small-box-footer">
                More info
                <i class="fas fa-arrow-circle-right"></i>
              </a>
            </div>
          </div>
          <!-- ./col -->
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
      <div class="container-fluid">
				<div class="row justify-content-center">
					<div class="col-12">
            <div>
              <button class="btn btn-dark mb-2" @click="cumulative = !cumulative">Switch</button> <br>
              <div v-if="!cumulative">
                <label class="mr-2">
                  Date from
                  <input style="height: 40px" type="date" v-model="nonCumulativeFrom">
                </label>
                <label>
                  Date to
                  <input style="height: 40px" type="date" v-model="nonCumulativeTo">
                </label>
                <select v-model="non_cumulative_chosen">
                  <option :value="non_cumulative_users">User</option>
                  <option :value="non_cumulative_invoices">Invoices</option>
                  <option :value="non_cumulative_companies">Companies</option>
                </select>
              </div>
            </div>
						<div v-if="cumulative" class="small-box bg-gradient-light">
							<div class="inner">
								<h3>Users / Companies / Invoices / Subscriptions Cumulative</h3>
								<line-chart :chartData="lineChartData" :options="line_options"/>
							</div>
            </div>
            <div v-else class="small-box bg-gradient-light">
              <div class="inner">
                <h3>Users / Invoices / Companies non-cumulative</h3>
                <line-chart :chartData="non_cumulative_chosen" :options="line_options"/>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-2">
            <div class="small-box bg-blue-light">
              <div class="inner">
                <h3>Invoice Status</h3>
                <doughnut-chart :chartData="status_distribution" :options="donut_options"/>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="small-box bg-green">
              <div class="inner">
                <h3>Country</h3>
                <doughnut-chart :chartData="country_distribution" :options="donut_options"/>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="small-box bg-blue-light">
              <div class="inner">
                <h3>Auth.Type</h3>
                <doughnut-chart :chartData="auth_distribution" :options="donut_options"/>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="small-box bg-blue-light">
              <div class="inner">
                <h3>Sub Plans</h3>
                <doughnut-chart :chartData="package_distribution" :options="donut_options"/>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="small-box bg-green">
              <div class="inner">
                <h3>Verified {{ verified_percentage.toFixed(2)}}%</h3>
                <doughnut-chart :chartData="verified_distribution" :options="donut_options"/>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="small-box bg-blue-light">
              <div class="inner">
                <h3>Sub Providers</h3>
                <doughnut-chart :chartData="provider_distribution" :options="donut_options"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
import DoughnutChart from "@/components/charts/DoughnutChart";
import LineChart from "@/components/charts/LineChart";
import instance from "@/utils/axios";
export default {
  name: "Dashboard",
  components: {LineChart, DoughnutChart},
  data() {
    return {
			country: "",
			dateFrom: "",
      cumulative: true,
      nonCumulativeFrom: "",
      nonCumulativeTo: "",
			//Fetched
			dashboard_stats: {
				invoices:{
					all: 0,
					last_7_days: 0,
				},
				users:{
					all: 0,
					last_7_days: 0,
				},
				subscriptions:{
					all: 0,
					last_7_days: 0,
				},
        companies: {
          chart:[]
        },
        churn:{
          truly_active: 0
        }
			},
			verified_distribution:{},
      invoices_dist: {},
			lineChartData: {},
      non_cumulative_invoices: [],
      non_cumulative_users: [],
      non_cumulative_companies: [],
      non_cumulative_chosen: [],
			auth_distribution: {},
			status_distribution: {},
			country_distribution: {},
			package_distribution: {},
			provider_distribution: {},
			line_options: {
				maintainAspectRatio: false,
				scales: {
					x: {
						display: true,
						scaleLabel: {
							display: true,
							labelString: 'Day'
						}
					},
					y: {
						display: true,
						scaleLabel: {
							display: true,
							labelString: 'Count'
						}
					}
				},
				hover: {
					mode: 'nearest',
					intersect: true
				},
				legend :{
					labels: {
						fontColor: "black"
					}
				}
			},
      donut_options: {
				legend: {
					labels: {
						fontColor: 'white'
					}
				}
			}
    }
  },
	async beforeRouteEnter(to, from, next) {
		next(vm => {
      vm.fetchNonCumulative();
		});
	},
  async beforeMount() {
    let ds = null
    try {
      ds = (await instance.get("/admin/dashboard/stats")).data;
    } catch (e) {
      console.error(e);
    }
    this.setStats(ds);
  },
	watch: {
		country() {
			this.refetchStats();
			this.fetchNonCumulative();
		},
		dateFrom() {
			this.refetchStats();
		},
    nonCumulativeFrom() {
      this.fetchNonCumulative();
    },
    nonCumulativeTo() {
      this.fetchNonCumulative();
    }
	},
  computed: {
    verified_percentage() {
      const users_stats = this.dashboard_stats.users;
      return (1 - users_stats.not_verified/users_stats.manual)*100;
    }
  },
  methods: {
    formatIncreases(data) {
      return `+ ${data.yesterday} / ${data.last_7_days} / ${data.last_30_days}`
    },
    async fetchNonCumulative() {
      let chosen = "";
      if (this.non_cumulative_chosen === this.non_cumulative_users)
        chosen = "users";
      else if (this.non_cumulative_chosen === this.non_cumulative_companies)
        chosen = "companies";
      else if (this.non_cumulative_chosen === this.non_cumulative_invoices)
        chosen = "invoices"

        try {
        const stats = (await instance.get(
            `/admin/dashboard/non-cumulative?country=${this.country}&from=${this.nonCumulativeFrom}&to=${this.nonCumulativeTo}`)).data;
        console.log(stats);
        this.non_cumulative_invoices = {
          labels: stats.labels,
          datasets: [{
            label: "Invoices",
            backgroundColor : "#f37451",
            borderColor : "#3FA9F5",
            data: stats.invoices,
            fill: false
          }]
        };
        this.non_cumulative_companies = {
          labels: stats.labels,
          datasets: [{
            label: "Companies",
            backgroundColor: "#f37451",
            borderColor: "#00D369",
            data: stats.accounts,
            fill: false,
          }]
        };
        this.non_cumulative_users = {
          labels: stats.labels,
          datasets: [{
            label: "Users",
            backgroundColor: "#3FA9F5",
            borderColor: "#f37451",
            data: stats.users,
            fill: false
          }]
        };
        //Set whicheverone was set before
        switch (chosen) {
          case "users":
            this.non_cumulative_chosen = this.non_cumulative_users;
            break
          case "companies":
            this.non_cumulative_chosen = this.non_cumulative_companies;

            break
          case "invoices":
            this.non_cumulative_chosen = this.non_cumulative_invoices;
            break
          default:
            this.non_cumulative_chosen = this.non_cumulative_users;
        }
      } catch (e) {
        console.error(e);
      }
    },
		async refetchStats(){
			try {
				const ds = (await instance.get(
						`/admin/dashboard/stats?country=${this.country}&from=${this.dateFrom}`)).data;
				this.setStats(ds);
			} catch (e) {
				console.error(e);
			}
		},

		setStats(ds) {
			if(!ds) return;
			this.dashboard_stats = ds;
			this.verified_distribution = this.getVerifiedDistribution();
			this.auth_distribution = this.getAuthDistribution();
			this.lineChartData = this.getLinechartData();
			this.status_distribution = this.getInvoicesStats();
			this.country_distribution = this.getCountryDistribution();
			this.package_distribution = this.getPackageDistribution();
			this.provider_distribution = this.getProviderDistribution();
		},
		getVerifiedDistribution() {
			const users = this.dashboard_stats.users;
			return {
				labels: ["Verified", "Not Verified"],
				datasets: [
					{
						backgroundColor: ["green", "#ece65d"],
						data: [users.manual - users.not_verified, users.not_verified]
					}
				]
			}
		},
		getProviderDistribution() {
			const subs = this.dashboard_stats.subscriptions;
			return {
				labels: ["Paypal", "Payze", "Kernel"],
				datasets: [
					{
						backgroundColor: ["yellow", "blue", "green"],
						data: [subs.paypal, subs.payze, subs.kernel]
					}
				]
			}
		},
		getPackageDistribution() {
			const subs = this.dashboard_stats.subscriptions;
			return {
				labels: ["Free", "Basic", "Premium"],
				datasets: [
					{
						backgroundColor: ["#f37451", "#ece65d","#3fa9f5"],
						data: [subs.bronze, subs.silver, subs.gold]
					}
				]
			}
		},
		getCountryDistribution() {
			const users = this.dashboard_stats.users;
			return {
				labels: ["Georgia", "Nigeria", "Pakistan", "Philippines", "India", "Other"],
				datasets: [
					{
						backgroundColor: ["red", "green", "orange", "coral", "cyan", "grey"],
						data: [users.georgia, users.nigeria, users.pakistan, users.philippines, users.india,
							users.all - users.georgia - users.nigeria - users.pakistan - users.philippines - users.india]
					}
				]
			}
		},
		getAuthDistribution() {
			return {
				labels: ["Facebook", "Google", "Email"],
				datasets: [
					{
						backgroundColor : ["#3b5998", "#0F9D58", "#f37451"],
						data: [this.dashboard_stats.users.facebook, this.dashboard_stats.users.google, this.dashboard_stats.users.manual],
					}
				]
			}
		},
		getLinechartData() {
			return {
				labels: this.monthDayLabels(),
				datasets: [
					{
						label: "Invoices",
						backgroundColor : "#f37451",
						borderColor : "#3FA9F5",
						data: this.dashboard_stats.invoices.chart,
						fill: false
					},
          {
            label: "Companies",
            backgroundColor : "#00ffcd",
            borderColor : "#1fdb0e",
            data: this.dashboard_stats.companies.chart,
            fill: false
          },
					{
						label: "Subscriptions",
						backgroundColor : "#f37451",
						borderColor : "#00D369",
						data: this.dashboard_stats.subscriptions.chart,
						fill: false,
						hidden: true
					},
					{
						label: "Users",
						backgroundColor : "#3FA9F5",
						borderColor : "#f37451",
						data: this.dashboard_stats.users.chart,
						fill: false
					}
				]
			}
		},
		monthDayLabels() {
			let days = [];
			const today = new Date();
			for(let i = 0; i < 30; i++) {
				let month = today.getMonth() + 1;
				if(month.toString().length === 1) month = "0"+month;
				days.unshift(today.getDate()+"/"+month);
				today.setDate(today.getDate() - 1);
			}
			return days
		},
    getInvoicesStats() {
			return {
				labels: ["Draft", "Paid", "Unpaid", "Overdue"],
				datasets: [
					{
						backgroundColor : ["#efd223", "#00d369", "#f37451", "#f22828"],
						data: [this.dashboard_stats.invoices.draft, this.dashboard_stats.invoices.paid,
							this.dashboard_stats.invoices.unpaid, this.dashboard_stats.invoices.overdue]
					}
				]
			}
		}
  }
};
</script>

<style>
.bg-blue-dark {
	background-color: #293961!important;
	color: white;
}
.bg-green {
	background-color: #00D369!important;
	color: white;
}
.bg-green-dark {
	background-color: #085B67!important;
	color: white;
}
.bg-blue-light {
	background-color: #3FA9F5!important;
	color: white;
}
.bg-coral {
	background-color: #f37451!important;
	color: white;
}
.bg-yellow {
	background-color: #f4e86e!important;
	color: #7ab6e6 !important;
}
.bg-gray {
	background-color: #F2F2F2!important;
	color: white;
}
.content-box {
	margin-top: 60px!important;
}

</style>
