<template>
    <div>
        <!-- Content Header (Page header) -->
        <section class="content-header">
        <div class="container-fluid content-box">
            <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Invoices</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item active">Subscriptions</li>
                </ol>
            </div>
            </div>
        </div>
        <!-- /.container-fluid -->
        </section>
        <div class="row justify-content-center">
            <div class="col col-2">
                <label>
                    <input type="number" min="1" v-model="invoice_id">
                </label>
                <button @click="downloadInvoice" class="btn btn-primary btn-block mt-2">
                    <b>Download</b>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import http from "@/utils/axios";

export default {
  name: "Invoices",
  data() {
      return {
          invoice_id: undefined
      }
  },
  methods: {
      async downloadInvoice() {
        let promise = http.get("/admin/invoices/sample-pdf");
        if(this.invoice_id) {
          promise = http.get(`/admin/invoices/${this.invoice_id}/pdf`);
        }
        try {
          let {data} = await promise;
          const uuid = data.uuid;
          window.open(`https://kernel.tools/api/invoices/${uuid}`, "_blank")
        } catch (e) {
          console.error(e);
        }
      }
  },
}
</script>