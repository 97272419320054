/* eslint-disable no-console */
import axios from 'axios';
import store from './../store';
import Cookies from "js-cookie"

const access_token = Cookies.get("access_token");
if(access_token) {
    store.dispatch("setToken", access_token).then();
}
const instance = axios.create({
  baseURL: 'https://admin.kernel.tools/api',
  // baseURL: 'http://localhost:8080/api',
  withCredentials: true,
});
instance.defaults.headers["Access-Control-Allow-Origin"] = 'https://admin.kernel.tools/api';

axios.interceptors.request.use(config => {
  // if (store.state.token) {
  //   axios.defaults.headers.common['Authorization'] =
  //     'Bearer ' + store.state.token;
  // }
  return config;
});

axios.interceptors.response.use(res => Promise.resolve(res), reject => {
      if(reject.response.status === 401) {
          Cookies.remove("access_token");
          store.dispatch("logOut").then()
      }
    }
);

export default instance;
